import type { PropsWithChildren } from "react";
import type { FieldValues, SubmitHandler } from "react-hook-form";
import type { ZodForm } from "@/components/form/types";

type FormProps<T extends FieldValues> = PropsWithChildren<{
  form: ZodForm<T>;
  onSubmit: SubmitHandler<T>;
}>;

export function Form<T extends FieldValues>({
  form,
  onSubmit,
  children,
}: FormProps<T>) {
  return (
    <form
      id={form.id}
      className="space-y-5"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      {children}
    </form>
  );
}
