import type { ButtonHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import type { BookableResponseDTO } from "@/client/private";
import { OptionSelect } from "@/components/common/OptionSelect";

type BookableSelectProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  bookableId?: string;
  bookables: BookableResponseDTO[] | undefined;
  onBookableSelected: (bookable: BookableResponseDTO | undefined) => void;
};

export function BookableSelect({
  bookableId,
  bookables,
  onBookableSelected,
  ...props
}: BookableSelectProps) {
  const { t } = useTranslation();

  return (
    <OptionSelect
      selectedId={bookableId}
      options={bookables}
      isLoading={!bookables}
      onOptionSelected={onBookableSelected}
      title={t("reservations.bookable_select.title")}
      placeholder={t("reservations.bookable_select.filter_bookables")}
      noItemsMessage={t("reservations.bookable_select.no_bookables")}
      {...props}
    />
  );
}
