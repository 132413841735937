import { addMinutes, differenceInMinutes } from "date-fns";
import type {
  AssignedPlannerReservationRequestLine,
  PlannerReservationLine,
  PlannerResource,
} from "@/components/planner/planner.model";
import type { ZoomLevel } from "@/components/planner/zoom-level";

export const SLOT_WIDTH_IN_REM = 10;
export const SLOT_HEIGHT_IN_REM = 4;

type ContainerSize = { width: number; height: number };

type TimeSpan = { startAt: Date; until: Date };
type LaneMap = Map<number, TimeSpan>;
type ResourceLaneMap = Map<string, number>;
type GridRowMap = Map<string, number>;

export class GridLayout {
  private readonly resourceColumns = 1;

  public constructor(
    public readonly currentDate: Date,
    public readonly timeSlots: Date[],
    public readonly firstSlotStart: Date,
    public readonly lastSlotEnd: Date,
    public readonly zoomLevel: ZoomLevel,
    private readonly tableRowMapping: GridRowMap,
    private readonly resourceLaneMapping: ResourceLaneMap,
  ) {}

  public getRowCount() {
    const laneCounts = Array.from(this.resourceLaneMapping.values());
    return laneCounts.reduce((acc, n) => acc + n, 0);
  }

  public getColumnCount() {
    return this.resourceColumns + this.timeSlots.length;
  }

  public getRow(resourceId: string, reservationLineId?: string) {
    const id = getPlannerItemId(resourceId, reservationLineId);
    return this.tableRowMapping.get(id) ?? 1;
  }

  public getLanes(resourceId: string) {
    return this.resourceLaneMapping.get(resourceId) ?? 1;
  }

  public getHeaderColumn(timeSlot: Date | undefined) {
    if (!timeSlot) {
      return 1;
    }

    // Headers only have time slots.
    // The slots overlap the resource time columns by 50% to display the time in the middle.
    return this.timeSlots.indexOf(timeSlot) + 1;
  }

  public getResourceColumn(timeSlot: Date) {
    if (!timeSlot) {
      return 1;
    }

    // Resource time slots start at column 2 (first column contains the resource names).
    return this.resourceColumns + this.timeSlots.indexOf(timeSlot) + 1;
  }

  public isLastResource(resourceId: string) {
    return Array.from(this.resourceLaneMapping.keys()).at(-1) === resourceId;
  }

  public isLastTimeSlot(timeSlot: Date) {
    return this.timeSlots.at(-1) === timeSlot;
  }

  public overlapsTimeSlots(item: { startAt: Date; until: Date }) {
    return item.startAt < this.lastSlotEnd && item.until > this.firstSlotStart;
  }

  public getMiddleSlotIndex() {
    return Math.floor(this.timeSlots.length / 2);
  }

  public getTimeSpanColumnStyle = ({
    startAt,
    until,
  }: {
    startAt: Date;
    until: Date;
  }) => {
    let startSlotIndex = this.timeSlots.findIndex(
      (slot) =>
        slot <= startAt &&
        addMinutes(slot, this.zoomLevel.slotSizeInMinutes) > startAt,
    );
    startSlotIndex = startSlotIndex === -1 ? 0 : startSlotIndex;
    const startSlot = this.timeSlots[startSlotIndex];

    let endSlotIndex = this.timeSlots.findIndex(
      (slot) =>
        slot < until &&
        addMinutes(slot, this.zoomLevel.slotSizeInMinutes) >= until,
    );
    endSlotIndex =
      endSlotIndex === -1 ? this.timeSlots.length - 1 : endSlotIndex;
    const endSlot = this.timeSlots[endSlotIndex];

    if (!startSlot || !endSlot) {
      return undefined;
    }

    const columnStart = this.resourceColumns + startSlotIndex + 1;
    const columnEnd = this.resourceColumns + endSlotIndex + 2;
    const gridSizeInMinutes =
      (columnEnd - columnStart) * this.zoomLevel.slotSizeInMinutes;

    const startOffset =
      startSlot >= startAt
        ? 0
        : differenceInMinutes(startAt, startSlot) / gridSizeInMinutes;

    const endSlotEnd = addMinutes(endSlot, this.zoomLevel.slotSizeInMinutes);
    const endOffset =
      endSlotEnd <= until
        ? 0
        : differenceInMinutes(endSlotEnd, until) / gridSizeInMinutes;

    return {
      gridColumn: `${columnStart} / ${columnEnd}`,
      marginLeft: `${startOffset * 100}%`,
      marginRight: `${endOffset * 100}%`,
    };
  };
}

/**
 * Determine the time slots (start dates) based on the first slot start, slot size and container size.
 */
export function createTimeSlots(
  firstSlotStart: Date,
  slotSizeInMinutes: number,
  containerSize: ContainerSize,
) {
  const remToPixels = (px: number) => {
    const pixelsPerRem =
      parseFloat(getComputedStyle(document.documentElement).fontSize) || 16;

    return px * pixelsPerRem;
  };

  const slotCount = Math.floor(
    containerSize.width / remToPixels(SLOT_WIDTH_IN_REM),
  );
  const lastSlotEnd = addMinutes(firstSlotStart, slotSizeInMinutes * slotCount);
  const timeSlots = Array.from({ length: slotCount }, (_, i) =>
    addMinutes(firstSlotStart, i * slotSizeInMinutes),
  );

  return { lastSlotEnd, timeSlots };
}

/**
 * Create a mapping between resource and reservation line ID's and grid row numbers.
 */
export function createTableRowMapping(
  resources: PlannerResource[],
  reservationLines: PlannerReservationLine[],
  requestLines: AssignedPlannerReservationRequestLine[],
) {
  const tableRowMapping = new Map<string, number>();

  // Keep track of the number of lanes per resource
  const resourceLaneMapping = new Map<string, number>();

  let rowNumber = 1; // Grid rows start at 1, first row is for navigation and time slots

  resources.forEach((resource) => {
    tableRowMapping.set(getPlannerItemId(resource.id), rowNumber);
    const laneMap = new Map<number, TimeSpan>();
    [...reservationLines, ...requestLines]
      .filter((line) => line.resourceId === resource.id)
      .sort((a, b) => a.startAt.getTime() - b.startAt.getTime())
      .forEach((line) => {
        const lane = getFirstAvailableLane(line.startAt, laneMap);
        laneMap.set(lane, line);

        const itemId = getPlannerItemId(resource.id, line.lineId);
        tableRowMapping.set(itemId, rowNumber + lane);
      });

    resourceLaneMapping.set(resource.id, getLastLaneNumber(laneMap) + 1);
    rowNumber += 1 + getLastLaneNumber(laneMap);
  });

  return { tableRowMapping, resourceLaneMapping };
}

function getLastLaneNumber(laneMap: LaneMap) {
  return laneMap.size === 0 ? 0 : laneMap.size - 1;
}

function getFirstAvailableLane(startAt: Date, laneMap: LaneMap) {
  for (const [lane, lastLaneReservation] of laneMap.entries()) {
    if (lastLaneReservation.until <= startAt) {
      return lane;
    }
  }

  return laneMap.size;
}

export function getPlannerItemId(
  resourceId: string,
  reservationLineId?: string,
) {
  return reservationLineId ? `${resourceId}-${reservationLineId}` : resourceId;
}
