import { ReservationDetails } from "@/components/planner/ReservationDetails";
import {
  getPlannerItemId,
  type GridLayout,
} from "@/components/planner/grid-layout";
import type {
  AssignedPlannerReservationRequestLine,
  PlannerReservationLine,
} from "@/components/planner/planner.model";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

type ReservationProps = {
  reservation: PlannerReservationLine | AssignedPlannerReservationRequestLine;
  isRequest: boolean;
  gridLayout: GridLayout;
};

export default function ReservationCell({
  reservation,
  isRequest,
  gridLayout,
}: ReservationProps) {
  const gridRow = gridLayout.getRow(reservation.resourceId, reservation.lineId);

  const columnStyle = gridLayout.getTimeSpanColumnStyle(reservation);
  const backgroundStyle = !isRequest ? "bg-sky-600/75" : "bg-sky-900";

  if (!columnStyle || !gridRow || !gridLayout.overlapsTimeSlots(reservation)) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger asChild className="cursor-pointer">
        <div
          key={getPlannerItemId(reservation.resourceId, reservation.lineId)}
          style={{
            ...columnStyle,
            gridRow: `${gridRow} / span 1`,
          }}
          className={`border-2 border-muted-foreground rounded-md min-w-0 min-h-0 ${backgroundStyle}`}
        >
          <div className="p-2 text-foreground">
            <div className="text-sm text-ellipsis overflow-hidden whitespace-nowrap">
              {reservation.name}
            </div>
            <div className="text-xs text-ellipsis overflow-hidden whitespace-nowrap">
              {reservation.bookableName} ({reservation.capacity})
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <ReservationDetails reservation={reservation} />
      </PopoverContent>
    </Popover>
  );
}
