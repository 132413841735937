import { useState } from "react";
import ResourceReservationGrid from "@/components/planner/ResourceReservationGrid";
import type { AssignedPlannerReservationRequestLine } from "@/components/planner/planner.model";
import { ReservationRequests } from "@/components/planner/requests/ReservationRequests";

export default function PlannerWithRequests() {
  const [assignedRequestLines, setAssignedRequestLines] = useState<
    AssignedPlannerReservationRequestLine[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  return (
    <div className="grid grid-rows-1 xl:grid-cols-[1fr_auto] gap-2 px-4">
      <ResourceReservationGrid
        requestLines={assignedRequestLines}
        initialDate={selectedDate}
      />
      <ReservationRequests
        onAssignedRequestsChange={setAssignedRequestLines}
        onCalendarSyncClick={(request) => setSelectedDate(request.startAt)}
      />
    </div>
  );
}
