import { Link } from "@tanstack/react-router";
import { CalendarClock, Edit } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type {
  AssignedPlannerReservationRequestLine,
  PlannerReservationRequest,
} from "@/components/planner/planner.model";
import { ReservationRequest } from "@/components/planner/requests/ReservationRequest";
import { useBookableResourceRequestData } from "@/components/planner/requests/use-bookable-resource-request-data";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { useDateFormat } from "@/hooks/use-date-format";

type ReservationRequestsProps = {
  onAssignedRequestsChange: (
    assignedRequests: AssignedPlannerReservationRequestLine[],
  ) => void;
  onCalendarSyncClick?: (request: PlannerReservationRequest) => void;
};

export function ReservationRequests({
  onAssignedRequestsChange,
  onCalendarSyncClick,
}: ReservationRequestsProps) {
  const { t } = useTranslation();
  const { formatDate } = useDateFormat();
  const { resources, requests } = useBookableResourceRequestData();

  const [expandedRequests, setExpandedRequests] = useState<string[]>([]);
  const [assignedRequestLines, setAssignedRequestLines] = useState<
    AssignedPlannerReservationRequestLine[]
  >([]);

  const handleResourceAssignment = (
    requestId: string,
    newAssignedRequestLines: AssignedPlannerReservationRequestLine[],
  ) => {
    const updatedLines = assignedRequestLines
      .filter((line) => line.requestId !== requestId)
      .concat(newAssignedRequestLines);
    setAssignedRequestLines(updatedLines);
    onAssignedRequestsChange(updatedLines);
  };

  return (
    <Card className="xl:w-80 w-full">
      <CardHeader>{t("reservation_requests.title")}</CardHeader>
      <CardContent>
        <Accordion
          type="multiple"
          value={expandedRequests}
          onValueChange={setExpandedRequests}
        >
          {requests.map((request) => {
            return (
              <AccordionItem key={request.id} value={request.id}>
                <AccordionTrigger>
                  <div className="flex flex-col items-start">
                    <span>{request.name}</span>
                    <small>{formatDate(request.startAt, "PPp")}</small>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="flex flex-col space-y-3">
                  <div className="flex gap-2">
                    <Button
                      variant={"secondary"}
                      size={"icon"}
                      onClick={() => onCalendarSyncClick?.(request)}
                      title={t("reservation_requests.sync_calendar")}
                    >
                      <CalendarClock className="h-4 w-4" />
                    </Button>
                    <Button
                      variant={"secondary"}
                      size={"icon"}
                      title={t("reservation_requests.edit_request")}
                      asChild
                    >
                      <Link
                        to="/reservation-requests/$id"
                        params={{ id: request.id }}
                      >
                        <Edit className="h-4 w-4" />
                      </Link>
                    </Button>
                  </div>

                  <ReservationRequest
                    request={request}
                    resources={resources}
                    onAssignedRequestLinesChange={(lines) =>
                      handleResourceAssignment(request.id, lines)
                    }
                  />
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </CardContent>
    </Card>
  );
}
