import { X } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OptionSelect } from "@/components/common/OptionSelect";
import type {
  AssignedPlannerReservationRequestLine,
  PlannerReservationRequestLine,
  PlannerResource,
} from "@/components/planner/planner.model";
import { Button } from "@/components/ui/button";

type ReservationRequestLineProps = {
  requestLine: PlannerReservationRequestLine;
  availableResources: PlannerResource[];
  onResourceAssignment: (
    assignedRequestLines: AssignedPlannerReservationRequestLine[],
  ) => void;
};

type ResourceAssignment = { resourceId: string; capacity: number };

export function ReservationRequestLine({
  requestLine,
  availableResources,
  onResourceAssignment,
}: ReservationRequestLineProps) {
  const { t } = useTranslation();
  const [assignedResources, setAssignedResources] = useState<
    ResourceAssignment[]
  >([]);

  const addResourceAssignment = (resourceId: string) => {
    const resource = availableResources.find(
      (resource) => resource.id === resourceId,
    );

    if (
      !resource ||
      assignedResources.some((assigned) => assigned.resourceId === resourceId)
    ) {
      return;
    }

    const assignedCapacity = assignedResources
      .filter((assignedResource) => assignedResource.resourceId !== resourceId)
      .reduce((acc, { capacity }) => acc + capacity, 0);
    const remainingCapacity = requestLine.capacity - assignedCapacity;

    const newAssignedResources = [
      ...assignedResources,
      {
        resourceId,
        capacity: Math.max(Math.min(remainingCapacity, resource.capacity), 0),
      },
    ];

    setAssignedResources(newAssignedResources);
    onResourceAssignment(
      mapResourceAssignmentToRequestLines(newAssignedResources),
    );
  };

  const removeResourceAssignment = (resourceId: string) => {
    const newAssignedResources = assignedResources.filter(
      (assigned) => assigned.resourceId !== resourceId,
    );
    setAssignedResources(newAssignedResources);
    onResourceAssignment(
      mapResourceAssignmentToRequestLines(newAssignedResources),
    );
  };

  const mapResourceAssignmentToRequestLines = (
    assignedResources: ResourceAssignment[],
  ) => {
    return assignedResources.map(({ resourceId, capacity }) => ({
      ...requestLine,
      resourceId,
      capacity,
    }));
  };

  const options = availableResources
    .filter(
      (availableResource) =>
        !assignedResources.some(
          (assignedResources) =>
            availableResource.id === assignedResources.resourceId,
        ),
    )
    .map((resource) => ({
      id: resource.id,
      name: `${resource.name} (${resource.capacity})`,
    }));

  const getResourceName = (resourceId: string) => {
    const resource = availableResources.find(
      (resource) => resource.id === resourceId,
    );
    return resource?.name;
  };

  return (
    <div className="flex flex-col gap-5">
      <OptionSelect
        options={options}
        onOptionSelected={(option) =>
          option && addResourceAssignment(option.id)
        }
        title={t("reservation_requests.resource_assignment.title")}
        noItemsMessage={t("reservation_requests.resource_assignment.no_items")}
      />
      <div className="flex flex-col gap-2">
        {assignedResources.map(({ resourceId, capacity }) => (
          <div key={resourceId} className="flex justify-between items-center">
            <span>
              {getResourceName(resourceId)} ({capacity})
            </span>
            <Button
              variant={"ghost"}
              size={"icon"}
              onClick={() => removeResourceAssignment(resourceId)}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
