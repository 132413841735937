import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import type {
  AssignedPlannerReservationRequestLine,
  PlannerReservationLine,
} from "@/components/planner/planner.model";

type ReservationDetailsProps = {
  reservation: PlannerReservationLine | AssignedPlannerReservationRequestLine;
};

export function ReservationDetails({ reservation }: ReservationDetailsProps) {
  const { t } = useTranslation();

  const popoverContent = [
    [t("bookables.bookable"), reservation.bookableName],
    [
      t("reservations.properties.start_at"),
      format(reservation.startAt, "dd MMM HH:mm"),
    ],
    [
      t("reservations.properties.until"),
      format(reservation.until, "dd MMM HH:mm"),
    ],
    [
      t("resources.properties.capacity"),
      `${reservation.capacity} ${reservation.capacityLabel}`,
    ],
  ];

  return (
    <>
      <span className="font-semibold">{reservation.name}</span>
      <ul className="grid gap-1">
        {popoverContent
          .filter(([, value]) => value)
          .map(([label, value]) => (
            <li key={label} className="flex justify-between">
              <span className="text-muted-foreground">{label}</span>
              <span>{value}</span>
            </li>
          ))}
      </ul>
    </>
  );
}
