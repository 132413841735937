import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import type { Row } from "@tanstack/table-core";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { CombinedReservationResponseDTO } from "@/client/private";
import { deleteReservationRequestsMutation } from "@/client/private/@tanstack/react-query.gen";
import { DataTableActionCell } from "@/components/data-table/cells/data-table-action-cell";
import { ReservationType } from "@/components/reservations/reservation-type.model";

type ActionCellProps = {
  row: Row<CombinedReservationResponseDTO>;
  onDataInvalidated: () => void;
};

export const ReservationTableActionCell = ({
  row,
  onDataInvalidated,
}: ActionCellProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteRequest = useMutation({ ...deleteReservationRequestsMutation() });

  const handleEdit = (row: Row<CombinedReservationResponseDTO>) => {
    if (row.original.type === ReservationType.RESERVATION) {
      navigate({ to: `/reservations/${row.id}` });
    } else {
      navigate({ to: `/reservation-requests/${row.id}` });
    }
  };

  const handleDelete = (row: Row<CombinedReservationResponseDTO>) => {
    deleteRequest.mutateAsync(
      {
        path: { id: row.original.id },
      },
      {
        onSuccess: () => {
          onDataInvalidated();
          toast.success(
            t("common.delete_success", {
              item: t("reservations.reservation_request"),
            }),
          );
        },
        onError: () =>
          toast.error(
            t("common.delete_error", {
              item: t("reservations.reservation_request"),
            }),
          ),
      },
    );
  };

  const actionHandlers = {
    handleEdit,
    handleDelete:
      row.original.type === ReservationType.RESERVATION_REQUEST
        ? handleDelete
        : undefined,
  };

  return DataTableActionCell<CombinedReservationResponseDTO>({
    row,
    actionHandlers,
  });
};
