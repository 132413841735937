import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: Array<ClassValue>) {
  return twMerge(clsx(inputs));
}

export function isNotEmpty<T>(value: T | undefined | null): value is T {
  return value != null;
}

export function isNotEmptyArray<T>(
  array: T[] | undefined,
): array is [T, ...T[]] {
  return array != null && array.length > 0;
}

export function uniqueValues<T>() {
  return (array: T[]) => array.filter((v, i) => array.indexOf(v) === i);
}
