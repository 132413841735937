import type { PropsWithChildren } from "react";
import type { FieldValues, SubmitHandler } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { Form } from "@/components/form/Form";
import type { ZodForm } from "@/components/form/types";

type FormContainerProps<T extends FieldValues> = PropsWithChildren<{
  form: ZodForm<T>;
  onSubmit: SubmitHandler<T>;
}>;

export function FormContainer<T extends FieldValues>({
  form,
  onSubmit,
  children,
}: FormContainerProps<T>) {
  return (
    <FormProvider {...form}>
      <Form form={form} onSubmit={onSubmit}>
        {children}
      </Form>
    </FormProvider>
  );
}
