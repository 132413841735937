import { useQuery } from "@tanstack/react-query";
import { listBookablesOptions } from "@/client/private/@tanstack/react-query.gen";

export function useBookableData() {
  const { data, isLoading } = useQuery({
    ...listBookablesOptions({
      body: {
        sort: { sortBy: "name", direction: "ASC" },
      },
    }),
  });

  return { bookables: data?.content, isBookablesLoading: isLoading };
}
