import { addHours, startOfHour } from "date-fns";
import type { TFunction } from "i18next";
import { z } from "zod";

export function createReservationRequestSchema(t: TFunction) {
  return z.object({
    window: z
      .object({
        startAt: z
          .date({
            required_error: t("common.field_required", {
              field: t("reservations.properties.start_at"),
            }),
          })
          .default(addHours(startOfHour(new Date()), 1))
          .describe(t("reservations.properties.start_at")),
        until: z
          .date({
            required_error: t("common.field_required", {
              field: t("reservations.properties.until"),
            }),
          })
          .default(addHours(startOfHour(new Date()), 2))
          .describe(t("reservations.properties.until")),
      })
      .refine(({ startAt, until }) => startAt < until, {
        message: t("reservations.add_request.start_before_end"),
        path: ["until"],
      }),
    clientName: z
      .string({
        required_error: t("common.field_required", {
          field: t("reservations.properties.name"),
        }),
      })
      .min(1, {
        message: t("common.field_required", {
          field: t("reservations.properties.name"),
        }),
      })
      .describe(t("reservations.properties.name")),
    clientEmail: z
      .string({
        required_error: t("common.field_required", {
          field: t("reservations.properties.email"),
        }),
      })
      .email({ message: t("reservations.add_request.email_invalid") })
      .describe(t("reservations.properties.email")),
    clientPhoneNumber: z
      .string()
      .nullish()
      .describe(t("reservations.properties.phone_number")),
    line: z
      .array(
        z.object({
          id: z.string(),
          bookableId: z.string(),
          capacity: z.number().int().min(0),
        }),
      )
      .refine(
        (lines) => lines.reduce((acc, { capacity }) => acc + capacity, 0) > 0,
        {
          message: t("reservations.add_request.non_zero_capacity"),
        },
      )
      .default([]),
  });
}

export type ReservationRequestSchemaValues = z.infer<
  ReturnType<typeof createReservationRequestSchema>
>;
