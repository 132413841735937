import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { toast } from "sonner";
import { deleteReservationRequestsMutation } from "@/client/private/@tanstack/react-query.gen";
import type { PlannerReservationRequest } from "@/components/planner/planner.model";
import { Button } from "@/components/ui/button";
import { useConfirmDialog } from "@/hooks/use-confirm-dialog";

type DeclineRequestButtonProps = {
  request: PlannerReservationRequest;
  onRequestDeclined: () => void;
};

export function DeclineRequestButton({
  request,
  onRequestDeclined,
}: DeclineRequestButtonProps) {
  const { mutate } = useMutation({
    ...deleteReservationRequestsMutation(),
  });
  const client = useQueryClient();

  const {
    confirm: confirmDeclineRequest,
    Dialog: UnassignedCapacityConfirmDialog,
  } = useConfirmDialog();

  const handleDeclineRequest = async () => {
    const confirmed = await confirmDeclineRequest();
    if (!confirmed) {
      return;
    }

    mutate(
      {
        path: { id: request.id },
      },
      {
        onSuccess: () => {
          toast.info(t("reservation_requests.decline_success"));
          client.invalidateQueries();
          onRequestDeclined();
        },
        onError: () => {
          toast.error(t("reservation_requests.decline_error"));
        },
      },
    );
  };

  return (
    <>
      <Button variant="outline" onClick={handleDeclineRequest}>
        {t("reservation_requests.decline")}
      </Button>
      <UnassignedCapacityConfirmDialog
        texts={{
          description: t("reservation_requests.confirm_decline"),
        }}
      />
    </>
  );
}
