import { useNavigate } from "@tanstack/react-router";
import type { Row } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type {
  CombinedReservationListDTO,
  CombinedReservationResponseDTO,
  PageCombinedReservationDTO,
} from "@/client/private";
import { listCombinedReservations, listView } from "@/client/private";
import { listCombinedReservationsQueryKey } from "@/client/private/@tanstack/react-query.gen";
import { AddItemButton } from "@/components/common/AddItemButton";
import { ItemListHeader } from "@/components/common/ItemListHeader";
import { DataTableLayoutWrapper } from "@/components/data-table/data-table-layout.tsx";
import { getReservationTableColumns } from "@/components/reservations/list/reservation-table-columns";
import { getReservationTableFilters } from "@/components/reservations/list/reservation-table-filters";
import { ReservationType } from "@/components/reservations/reservation-type.model";
import { useDataTableSettings } from "@/hooks/use-data-table-settings.ts";

export const ReservationTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    content,
    totalPages,
    pageIndex,
    setPageIndex,
    listSettings,
    onListSettingsChange,
    totalElements,
    isFetching,
    refetch,
  } = useDataTableSettings<
    PageCombinedReservationDTO,
    CombinedReservationListDTO
  >(
    listCombinedReservations,
    listCombinedReservationsQueryKey,
    listView.COMBINED_RESERVATION,
  );

  const filterFields = useMemo(() => getReservationTableFilters(t), [t]);
  const columns = useMemo(
    () => getReservationTableColumns(t, refetch),
    [t, refetch],
  );

  const handleRowClick = (row: Row<CombinedReservationResponseDTO>) => {
    if (row.original.type === ReservationType.RESERVATION) {
      navigate({ to: `/reservations/${row.id}` });
    } else {
      navigate({ to: `/reservation-requests/${row.id}` });
    }
  };

  return (
    <DataTableLayoutWrapper
      above={
        <ItemListHeader
          title={t("reservations.title")}
          description={t("reservations.description")}
          totalElements={totalElements}
        />
      }
      toolbar={
        <AddItemButton
          onClick={() => navigate({ to: "/reservation-requests/add" })}
          item={t("reservations.reservation_request")}
        />
      }
      columns={columns}
      content={content}
      totalPages={totalPages}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      onRowClick={handleRowClick}
      filterFields={filterFields}
      listSettings={listSettings}
      onListSettingsChange={onListSettingsChange}
      isFetching={isFetching}
    />
  );
};
