import { useState } from "react";
import type { ConfirmDialogTexts } from "@/components/common/ConfirmDialog";
import { ConfirmDialog } from "@/components/common/ConfirmDialog";

export function useConfirmDialog() {
  const [promise, setPromise] = useState<null | {
    resolve: (value: boolean) => void;
  }>(null);

  const confirm = () => {
    return new Promise<boolean>((resolve) => {
      setPromise({ resolve });
    });
  };

  const handleClose = () => {
    setPromise(null);
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const Dialog = ({ texts }: { texts: ConfirmDialogTexts }) => (
    <ConfirmDialog
      isOpen={promise !== null}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      texts={texts}
    />
  );

  return { Dialog, confirm };
}
