import { useId } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { BookableResponseDTO } from "@/client/private";
import { DataTableSkeleton } from "@/components/data-table/data-table-skeleton";
import { BookableCapacityTable } from "@/components/reservations/bookable-capacity/BookableCapacityTable";
import { BookableSelect } from "@/components/reservations/bookable-capacity/BookableSelect";
import type { MutableReservationRequestLine } from "@/components/reservations/bookable-capacity/mutable-reservation-request-line.model";
import { useBookableData } from "@/components/reservations/bookable-capacity/use-bookable-data";
import type { ReservationRequestSchemaValues } from "@/components/reservations/reservation-request-schema";
import { Alert } from "@/components/ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FormMessage } from "@/components/ui/form";
import { FormlessField, FormlessForm } from "@/components/ui/formless";
import { Label } from "@/components/ui/label";

export function BookableCapacitySelection() {
  const { t } = useTranslation();
  const bookableSelectId = useId();
  const form = useFormContext<ReservationRequestSchemaValues>();

  const { bookables, isBookablesLoading } = useBookableData();

  const validationError = form.getFieldState("line").error?.message;
  const requestLines = form.getValues("line");
  const onLineChange = (
    updatedRequestLines: MutableReservationRequestLine[],
  ) => {
    form.setValue("line", updatedRequestLines, { shouldValidate: true });
  };

  const handleBookableSelected = (
    bookable: BookableResponseDTO | undefined,
  ) => {
    if (bookable && !requestLines.some((rl) => rl.bookableId === bookable.id)) {
      onLineChange([
        ...requestLines,
        {
          id: crypto.randomUUID(),
          bookableId: bookable.id,
          capacity: 1,
        },
      ]);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("reservations.bookable_capacity.title")}</CardTitle>
      </CardHeader>
      <CardContent>
        {validationError && (
          <FormMessage className="pb-3">{validationError}</FormMessage>
        )}
        <FormlessForm className="mb-4">
          <FormlessField>
            <Label htmlFor={bookableSelectId}>{t("bookables.bookable")}</Label>
            <BookableSelect
              className="min-w-72 justify-start"
              id={bookableSelectId}
              bookables={bookables}
              onBookableSelected={handleBookableSelected}
            />
          </FormlessField>
        </FormlessForm>

        {isBookablesLoading ? (
          <DataTableSkeleton
            columnCount={5}
            rowCount={requestLines.length}
            showViewOptions={false}
            withPagination={false}
          />
        ) : requestLines.length === 0 ? (
          <Alert>{t("reservations.bookable_capacity.select_bookable")}</Alert>
        ) : (
          <BookableCapacityTable
            bookables={bookables ?? []}
            requestLines={requestLines}
            onChange={onLineChange}
          />
        )}
      </CardContent>
    </Card>
  );
}
