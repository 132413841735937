import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { createReservationRequestMutation } from "@/client/private/@tanstack/react-query.gen";
import {
  FormDateTime,
  FormInput,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { Form } from "@/components/form/Form";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import { BookableCapacitySelection } from "@/components/reservations/bookable-capacity/BookableCapacitySelection";
import { useMutateReservationRequestLines } from "@/components/reservations/bookable-capacity/use-mutate-reservation-request-lines";
import type { ReservationRequestSchemaValues } from "@/components/reservations/reservation-request-schema";
import { createReservationRequestSchema } from "@/components/reservations/reservation-request-schema";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export function AddReservationRequestForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const createRequest = useMutation({
    ...createReservationRequestMutation(),
  });
  const { mutateLines } = useMutateReservationRequestLines();

  const schema = createReservationRequestSchema(t);
  const form = useZodForm({ schema });
  //const startAt = form.watch("window.startAt");

  const handleSubmit = (values: ReservationRequestSchemaValues) => {
    createRequest.mutate(
      {
        body: {
          startAt: values.window.startAt,
          until: values.window.until,
          name: values.clientName,
          email: values.clientEmail ?? undefined,
          phoneNumber: values.clientPhoneNumber ?? undefined,
        },
      },
      {
        onSuccess: (requestId) => {
          mutateLines({
            reservationRequestId: requestId,
            originalLines: [],
            mutatedLines: values.line,
            onSuccess: onSuccess,
            onError: onError,
          });
        },
        onError: onError,
      },
    );
  };

  const onSuccess = () => {
    toast.success(
      t("common.create_success", {
        item: t("reservations.reservation_request"),
      }),
    );
    navigate({ to: "/reservations" });
  };

  const onError = () => {
    toast.error(
      t("common.create_error", {
        item: t("reservations.reservation_request"),
      }),
    );
  };

  const buttons = (
    <FormSubmit form={form} mutation={createRequest}>
      {t("common.save")}
    </FormSubmit>
  );

  return (
    <FormProvider {...form}>
      <EditItemContainer
        title={t("reservations.add_request.title")}
        buttons={buttons}
        backTo={{ to: "/reservations" }}
        sidePanel={<BookableCapacitySelection />}
      >
        <Card>
          <CardHeader>
            <CardTitle>{t("reservations.reservation_request")}</CardTitle>
            <CardDescription>
              {t("reservations.add_request.description")}
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-5">
            <Form form={form} onSubmit={handleSubmit}>
              <FormDateTime
                name="startAt"
                path={["window"]}
                schema={schema.shape.window}
              />
              <FormDateTime
                name="until"
                path={["window"]}
                schema={schema.shape.window}
              />
              <FormInput name="clientName" schema={schema} />
              <FormInput name="clientEmail" schema={schema} />
              <FormInput name="clientPhoneNumber" schema={schema} />
            </Form>
          </CardContent>
        </Card>
        {/*
      <ReservationPlanner
        startAt={startAt}
        reservationLines={reservationLines}
      /> */}
      </EditItemContainer>
    </FormProvider>
  );
}
