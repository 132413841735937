import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import type { Row } from "@tanstack/react-table";
import type { ReactNode } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import type { DataTableActionHandlers } from "@/components/data-table/cells/data-table-action-handlers";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useConfirmDialog } from "@/hooks/use-confirm-dialog";

export type DataTableActionCellProps<T> = {
  row: Row<T>;
  actionHandlers: DataTableActionHandlers<T>;
};

export const DataTableActionCell = <T,>({
  row,
  actionHandlers,
}: DataTableActionCellProps<T>) => {
  const { t } = useTranslation();
  const { handleEdit, handleDelete } = actionHandlers;
  const { confirm: confirmDelete, Dialog: ConfirmDeleteDialog } =
    useConfirmDialog();

  const handleDeleteWithConfirmation = () => {
    confirmDelete().then((confirmed) => {
      if (confirmed) {
        handleDelete?.(row);
      }
    });
  };

  const actionItems: { key: string; item: ReactNode }[] = [];

  if (handleEdit) {
    actionItems.push({
      key: "edit",
      item: (
        <DropdownMenuItem onClick={() => handleEdit(row)}>
          {t("common.edit")}
        </DropdownMenuItem>
      ),
    });
  }

  if (handleDelete) {
    actionItems.push({
      key: "delete",
      item: (
        <DropdownMenuItem onClick={handleDeleteWithConfirmation}>
          {t("common.delete")}
        </DropdownMenuItem>
      ),
    });
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            aria-label={t("table.open_menu_label")}
            variant="ghost"
            className="flex size-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="size-4" aria-hidden="true" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-40">
          {actionItems.map(({ key, item }, index) => (
            <React.Fragment key={key}>
              {index > 0 && <DropdownMenuSeparator />}
              {item}
            </React.Fragment>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      <ConfirmDeleteDialog
        texts={{
          cancel: t("common.no"),
          confirm: t("common.yes"),
          description: t("common.confirm_delete"),
        }}
      />
    </>
  );
};
