import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { ReservationRequestResponseDTO } from "@/client/private";
import { updateReservationRequestsMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import {
  FormDateTime,
  FormInput,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { Form } from "@/components/form/Form";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import { BookableCapacitySelection } from "@/components/reservations/bookable-capacity/BookableCapacitySelection";
import { useMutateReservationRequestLines } from "@/components/reservations/bookable-capacity/use-mutate-reservation-request-lines";
import type { ReservationRequestSchemaValues } from "@/components/reservations/reservation-request-schema";
import { createReservationRequestSchema } from "@/components/reservations/reservation-request-schema";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface EditReservationRequestFormProps {
  request: ReservationRequestResponseDTO;
}

export function EditReservationRequestForm({
  request,
}: EditReservationRequestFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({ ...updateReservationRequestsMutation() });
  const { mutateLines } = useMutateReservationRequestLines();

  const schema = createReservationRequestSchema(t);
  const form = useZodForm({
    schema,
    values: {
      window: {
        startAt: request.startAt,
        until: request.until,
      },
      clientName: request.name,
      clientEmail: request.email,
      clientPhoneNumber: request.phoneNumber,
      line: request.line,
    },
  });
  //const startAt = form.watch("window.startAt");

  const handleSubmit = (values: ReservationRequestSchemaValues) => {
    update.mutate(
      {
        path: { id: request.id },
        body: {
          startAt: values.window.startAt,
          until: values.window.until,
          name: values.clientName,
          email: values.clientEmail,
          phoneNumber: values.clientPhoneNumber ?? undefined,
        },
      },
      {
        onSuccess: () => {
          mutateLines({
            reservationRequestId: request.id,
            originalLines: request.line,
            mutatedLines: values.line,
            onSuccess,
            onError,
          });
        },
        onError,
      },
    );
  };

  const onSuccess = () => {
    toast.success(
      t("common.edit_success", {
        item: t("reservations.reservation_request"),
      }),
    );
    navigate({ to: "/reservations" });
  };

  const onError = () =>
    toast.error(
      t("common.edit_error", {
        item: t("reservations.reservation_request"),
      }),
    );

  const buttons = (
    <FormSubmit form={form} mutation={update}>
      {t("common.save")}
    </FormSubmit>
  );

  return (
    <FormProvider {...form}>
      <EditItemContainer
        title={`${t("reservations.reservation_request")} ${request.name}`}
        buttons={buttons}
        backTo={{ to: "/reservations" }}
        sidePanel={<BookableCapacitySelection />}
      >
        <Card>
          <CardHeader>
            <CardTitle>{t("reservations.edit_request.title")}</CardTitle>
            <CardDescription>
              {t("reservations.edit_request.description")}
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-5">
            <Form form={form} onSubmit={handleSubmit}>
              <FormDateTime
                name="startAt"
                path={["window"]}
                schema={schema.shape.window}
              />
              <FormDateTime
                name="until"
                path={["window"]}
                schema={schema.shape.window}
              />
              <FormInput name="clientName" schema={schema} />
              <FormInput name="clientEmail" schema={schema} />
              <FormInput name="clientPhoneNumber" schema={schema} />
            </Form>
          </CardContent>
        </Card>
        {/*
      <ReservationPlanner
        startAt={startAt}
        reservationLines={reservationLines}
      /> */}
      </EditItemContainer>
    </FormProvider>
  );
}
