import { useState } from "react";
import type {
  AssignedPlannerReservationRequestLine,
  PlannerReservationRequest,
  PlannerResource,
} from "@/components/planner/planner.model";
import { AcceptRequestButton } from "@/components/planner/requests/AcceptRequestButton";
import { DeclineRequestButton } from "@/components/planner/requests/DeclineRequestButton";
import { ReservationRequestLine } from "@/components/planner/requests/ReservationRequestLine";
import type { RequestLineMap } from "@/components/planner/requests/request-line-map.model";
import { Card, CardContent, CardHeader } from "@/components/ui/card";

type ReservationRequestProps = {
  resources: PlannerResource[];
  request: PlannerReservationRequest;
  onAssignedRequestLinesChange: (
    assignedRequestLines: AssignedPlannerReservationRequestLine[],
  ) => void;
};

export function ReservationRequest({
  resources,
  request,
  onAssignedRequestLinesChange,
}: ReservationRequestProps) {
  const [assignedRequestLines, setAssignedRequestLines] =
    useState<RequestLineMap>(new Map());

  const handleResourceAssignment = (
    lineId: string,
    newAssignedRequestLines: AssignedPlannerReservationRequestLine[],
  ) => {
    const newMap = new Map(assignedRequestLines);
    newMap.set(lineId, newAssignedRequestLines);
    setAssignedRequestLines(newMap);
    onAssignedRequestLinesChange([...newMap.values()].flat());
  };

  const resetAssignedRequestLines = () => {
    setAssignedRequestLines(new Map());
    onAssignedRequestLinesChange([]);
  };

  return (
    <>
      {request.line.map((line) => {
        const availableResources = resources.filter((resource) =>
          resource.bookableId.includes(line.bookableId),
        );

        return (
          <Card key={line.lineId}>
            <CardHeader>
              <div className="flex flex-row justify-between">
                <span>{line.bookableName}</span>
                <span>{`${line.capacity} ${line.capacityLabel}`}</span>
              </div>
            </CardHeader>
            <CardContent>
              <ReservationRequestLine
                requestLine={line}
                availableResources={availableResources}
                onResourceAssignment={(assigned) =>
                  handleResourceAssignment(line.lineId, assigned)
                }
              />
            </CardContent>
          </Card>
        );
      })}

      <div className="flex flex-wrap justify-between">
        <DeclineRequestButton
          request={request}
          onRequestDeclined={resetAssignedRequestLines}
        />

        <AcceptRequestButton
          request={request}
          assignedRequestLines={assignedRequestLines}
          onRequestAssigned={resetAssignedRequestLines}
        />
      </div>
    </>
  );
}
