import { useMutation } from "@tanstack/react-query";
import {
  createReservationRequestLineMutation,
  deleteReservationRequestsLineMutation,
  updateReservationRequestLineMutation,
} from "@/client/private/@tanstack/react-query.gen";
import type { MutableReservationRequestLine } from "@/components/reservations/bookable-capacity/mutable-reservation-request-line.model";

export type MutateLineParams = {
  reservationRequestId: string;
  originalLines: MutableReservationRequestLine[];
  mutatedLines: MutableReservationRequestLine[];
  onSuccess: () => void;
  onError: (error: unknown) => void;
};

export function useMutateReservationRequestLines() {
  const create = useMutation({ ...createReservationRequestLineMutation() });
  const update = useMutation({ ...updateReservationRequestLineMutation() });
  const remove = useMutation({ ...deleteReservationRequestsLineMutation() });

  const mutateLines = ({
    reservationRequestId,
    originalLines,
    mutatedLines,
    onSuccess,
    onError,
  }: MutateLineParams) => {
    const toDelete = originalLines.filter(
      (ol) => !mutatedLines.some((ml) => ml.id === ol.id),
    );
    const toCreate = mutatedLines.filter(
      (ml) => !originalLines.some((ol) => ol.id === ml.id),
    );
    const toUpdate = mutatedLines.filter((ml) =>
      originalLines.some((ol) => ol.id === ml.id && lineHasChanged(ol, ml)),
    );

    toDelete.forEach((line) => {
      remove.mutate(
        { path: { id: line.id, reservationRequestId } },
        { onSuccess, onError },
      );
    });

    toCreate.forEach((ml) => {
      const { id, ...body } = ml;
      create.mutate(
        { path: { reservationRequestId }, body },
        { onSuccess, onError },
      );
    });

    toUpdate.forEach((ml) => {
      update.mutate(
        {
          path: { id: ml.id, reservationRequestId },
          body: ml,
        },
        { onSuccess, onError },
      );
    });

    if (!toDelete.length && !toCreate.length && !toUpdate.length) {
      // Make sure on success is called if there were no mutations
      onSuccess();
    }
  };

  return { mutateLines };
}

function lineHasChanged(
  original: MutableReservationRequestLine,
  mutated: MutableReservationRequestLine,
) {
  return (
    original.capacity !== mutated.capacity ||
    original.bookableId !== mutated.bookableId
  );
}
