import {
  addHours,
  getHours,
  roundToNearestHours,
  startOfDay,
  subHours,
} from "date-fns";

export type ZoomLevel = {
  id: string;
  titleUnits: string;
  titleCount: number;
  getStartSlot: () => Date;
  getDateSelectedStartSlot: (date: Date) => Date;
  slotSizeInMinutes: number;
  slotFormats: string[];
};

const getDateStart = (date: Date = new Date()) => startOfDay(date);

export const zoomLevels: ZoomLevel[] = [
  {
    id: "0",
    titleUnits: "date_time.hours",
    titleCount: 1,
    getStartSlot: () => addHours(getDateStart(), getHours(new Date()) - 1),
    getDateSelectedStartSlot: (date) =>
      subHours(roundToNearestHours(date, { roundingMethod: "floor" }), 1),
    slotSizeInMinutes: 60,
    slotFormats: ["HH:mm"],
  },
  {
    id: "1",
    titleUnits: "date_time.hours",
    titleCount: 6,
    getStartSlot: () =>
      addHours(getDateStart(), Math.floor(getHours(new Date()) / 6) - 1),
    getDateSelectedStartSlot: (date) =>
      subHours(
        roundToNearestHours(date, { roundingMethod: "floor", nearestTo: 6 }),
        6,
      ),
    slotSizeInMinutes: 6 * 60,
    slotFormats: ["dd MMM", "HH:mm"],
  },
  {
    id: "2",
    titleUnits: "date_time.hours",
    titleCount: 12,
    getStartSlot: () => addHours(getDateStart(), -12),
    getDateSelectedStartSlot: (date) => addHours(getDateStart(date), -12),
    slotSizeInMinutes: 12 * 60,
    slotFormats: ["dd MMM", "HH:mm"],
  },
  {
    id: "3",
    titleUnits: "date_time.days",
    titleCount: 1,
    getStartSlot: () => addHours(getDateStart(), -24),
    getDateSelectedStartSlot: (date) => addHours(getDateStart(date), -24),
    slotSizeInMinutes: 24 * 60,
    slotFormats: ["dd MMM"],
  },
];

export function getZoomLevel(id: string) {
  const result = zoomLevels.find((zoomLevel) => zoomLevel.id === id);
  if (!result) {
    throw new Error(`Zoom level with id ${id} not found.`);
  }
  return result;
}
